import Icon from '@mdi/react';
import React, { useEffect, useMemo } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { ISlotData } from '../../global';
import { getSlotsCatalog } from '../../services/slot';
import { Spinner } from '../bootstrap';
import CatalogItem from '../CatalogItem/CatalogItem';

const CarouselLoader: React.FC = () => (
  <div className="d-flex align-items-center justify-content-center" style={{ height: '415px' }}>
    <Spinner className="m-4" animation="grow" />
  </div>
);

const CatalogCarousel: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [slots, setSlots] = React.useState<ISlotData[]>([]);

  useEffect(() => {
    getSlotsCatalogFn();
  }, []);

  const getSlotsCatalogFn = async () => {
    try {
      setIsLoading(true);

      const data = await getSlotsCatalog();

      setSlots(data);
    } catch (error) {
      console.error('getSlotsCatalogFn', error);
    } finally {
      setIsLoading(false);
    }
  };

  const items = useMemo(() => slots.map((slot) => <CatalogItem key={slot.gameId} slot={slot} />), [slots]);

  const carouselConfig = {
    responsive: {
      0: { items: 1 },
      768: { items: 2 },
      1024: { items: 3, itemsFit: 'contain' },
    },
    controlsStrategy: 'responsive',
    mouseTracking: true,
    renderPrevButton: () => <Icon className="c-pointer" path={mdiChevronLeft} size={1} />,
    renderNextButton: () => <Icon className="c-pointer" path={mdiChevronRight} size={1} />,
  };

  if (isLoading) return <CarouselLoader />;

  return <AliceCarousel {...carouselConfig} items={items} />;
};

export default CatalogCarousel;
