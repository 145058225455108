import Icon from '@mdi/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { mdiFullscreen } from '@mdi/js';
import { Container, Spinner } from '../components/bootstrap';
import { CasinoId } from '../config';
import { ISlotData } from '../global';
import { useAppSelector } from '../hooks';
import { getSlot } from '../services/slot';
import { demoModeAdapterURL, getGameUrl } from '../utils';

interface DocumentWithFullscreen extends HTMLDocument {
  webkitFullscreenElement?: Element;
  webkitExitFullscreen?(): void;
  mozCancelFullScreen?(): Promise<void>;
  msExitFullscreen?(): Promise<void>;
}

interface HTMLElementWithFullscreen extends HTMLElement {
  webkitRequestFullscreen?(): void;
  mozRequestFullScreen?(): Promise<void>;
  msRequestFullscreen?(): Promise<void>;
}

function isFullScreen(el: React.RefObject<Element> | null) {
  const doc = document as DocumentWithFullscreen;
  const fsElement = doc.fullscreenElement ?? doc.webkitFullscreenElement;

  if (el && el.current) {
    return Boolean(fsElement === el.current);
  }

  return Boolean(fsElement);
}

function useFullScreen<T extends HTMLElementWithFullscreen>(
  el: React.RefObject<T> | null = null,
): [boolean, () => void] {
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    const handleChange = () => {
      setFullScreen(isFullScreen(el));
    };
    document.addEventListener('webkitfullscreenchange', handleChange);
    document.addEventListener('fullscreenchange', handleChange);
    handleChange();
    return () => {
      document.removeEventListener('webkitfullscreenchange', handleChange);
      document.removeEventListener('fullscreenchange', handleChange);
    };
  }, [el]);

  const toggle = useCallback(() => {
    if (fullScreen) {
      const doc = document as DocumentWithFullscreen;
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen().catch(console.error);
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen().catch(console.error);
      }
    }

    const target: HTMLElementWithFullscreen = (el && el.current) ?? document.documentElement;
    if (target.requestFullscreen) {
      target.requestFullscreen().catch(console.error);
    } else if (target.webkitRequestFullscreen) {
      target.webkitRequestFullscreen();
    } else if (target.mozRequestFullScreen) {
      target.mozRequestFullScreen().catch(console.error);
    } else if (target.msRequestFullscreen) {
      target.msRequestFullscreen().catch(console.error);
    }
  }, [fullScreen, el]);

  return [fullScreen, toggle];
}

const getDemoGameUrl = (slot: Partial<ISlotData>) => {
  const url = new URL(demoModeAdapterURL);

  url.pathname = '/api/external/game/start';

  url.searchParams.append('gameId', slot?.gameId || '');
  url.searchParams.append('redirect', 'true');

  if (slot?.gameName) {
    const gameDomain = slot?.gameName.replaceAll(' ', '-').toLowerCase();
    const gameUrl = new URL(getGameUrl(gameDomain));

    gameUrl.pathname = '/';

    url.searchParams.append('urlPrefix', gameUrl.toString());
  }

  return url.toString();
};

const getRealGameUrl = (slot: Partial<ISlotData>, token: string) => {
  if (!slot?.gameName) return '';
  const gameDomain = slot?.gameName.replaceAll(' ', '-').toLowerCase();
  const url = new URL(getGameUrl(gameDomain));

  url.searchParams.append('slotId', slot?.gameId || '');
  url.searchParams.append('clientId', CasinoId);
  url.searchParams.append('token', token);
  url.searchParams.append('lng', 'en');
  url.searchParams.append('isDemo', '');

  return url.toString();
};

// https://neon-palace.testing.moneyenergy.xyz/?slotId=7acf0ddf-ad1d-4995-810a-ff25dfd2a43c&clientId=00000001-0000-0000-0000-000000000000&token=a77b08d1-ec03-425a-a8aa-a05d85b66df2&lng=en&isDemo=
// https://neon-palace.testing.moneyenergy.xyz/?slotId=7acf0ddf-ad1d-4995-810a-ff25dfd2a43c&clientId=00000001-0000-0000-0000-000000000000&token=97fa3e2b-d771-4ef8-bcc7-06585fb73117&lng=en&isDemo=
const Game: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [slot, setSlot] = React.useState<Partial<ISlotData> | null>(null);
  const { id: gameId } = useParams<{ id: string }>();
  const state: { slot: Partial<ISlotData> } = useLocation().state;
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
  const [searchParams] = useSearchParams();
  const [, toggleFullScreen] = useFullScreen(iframeRef);
  const token = useAppSelector((state) => state.auth.token) || '';

  const isDemo = searchParams.has('isDemo');

  const getSlotFn = async (id: string) => {
    try {
      setIsLoading(true);
      const data = await getSlot(id);

      setSlot(data);
    } catch (error) {
      console.error('getSlotFn', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state?.slot) {
      setSlot(state.slot);
    } else if (gameId) {
      getSlotFn(gameId);
    }
  }, [gameId, state]);

  const iframeUrl = useMemo(() => {
    if (!slot) return '';

    return isDemo ? getDemoGameUrl(slot) : getRealGameUrl(slot, token);
  }, [slot]);

  if (isLoading)
    return (
      <Container className="d-flex align-center justify-content-center">
        <Spinner animation="grow" />
      </Container>
    );

  return (
    <Container>
      {slot ? (
        <>
          <h1>{slot?.gameName}</h1>
          <button className="btn fs-btn" onClick={toggleFullScreen}>
            <Icon className="me-2" path={mdiFullscreen} size={1} />
          </button>
          <div className="iframe">
            <iframe
              allowFullScreen
              allow="fullscreen"
              src={iframeUrl}
              width="100%"
              height="500px"
              title="Game"
              ref={iframeRef}
            />
          </div>
        </>
      ) : (
        <h1 className="text-center">Game not found</h1>
      )}
    </Container>
  );
};

export default Game;
